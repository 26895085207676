:root {
	--mdc-theme-primary: #e22b88;
}

html, body, #root {
	height: 100%;
	margin: 0;
}

.card {
	display: flex;
	flex-direction: column;
	width: 90%;
	margin: 1em;
}

.screen {
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;
	
	height: 100%;
}
