
.topBar {
	background-color: #e22b88;
}

.options {
	float: right;
	height: 100%;
	display: flex;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
